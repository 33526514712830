// Small Hover Newsletter Box
.fixed {
  &__container {
    width: 300px;
    height: 100px;
    background: #d7ffd4;
    border: 1px solid #222322;
    box-shadow: 5px 5px 8px 0px #999;
    z-index: 50000;
    position: fixed;
    border-radius: 9px;
    font-size:1.6rem;
    bottom: 25px;
    right: 25px;
  }

  &__title {
    padding:5px;
    font-size:2rem;
    font-weight:bold;
  }
}




.modal {
    &__overlay {
    position: fixed;
    z-index:1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.6);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  &__container {
    background-color: #fff;
    position:relative;
    padding: 30px;
    max-width: 700px;
    max-height: 100vh;
    border-radius: 4px;
    box-sizing: border-box;

    @include medium {
        max-width:90%;
    }
  }
  
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  &__title {
    font-size: 2.7rem;
    margin-bottom:1rem;
    font-weight:bold;
    color: maroon;
  }
  
  &__close {
    background: #f9f6f6;
    border: 2px solid #cac9c9;
    height: 30px;
    font-size: 2.5rem;
    font-weight:bold;
    width: 30px;
    border-radius: 50%;
    position: absolute;
    top: 3px;
    right: 3px;
  }
  
  
  &__content {
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size:2rem;
    line-height: 1.5;
    color: rgba(0,0,0,.8);

    ul {
        margin-left: 3.5rem;
        margin-top:1rem;
        font-weight: bold;
        font-style: italic;
        list-style-type: none;

        @include small {
            margin-left:.5rem;
            margin-right:.5rem;
            list-style-type: disc;
        }

        li {
            margin-bottom:6px;
            &:before { content:"\2714\0020"; color:#0ab30a; margin-right:1rem;}
            @include small { &:before { content:""}}
        }
    }
  }
  
  &__btn {
    font-size: 1rem;
    float:right;
    position: relative;
    bottom: -15px;
    right: -15px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    background-color: #e6e6e6;
    color: rgba(0,0,0,.8);
    border-radius: .25rem;
    border-style: none;
    border-width: 0;
    cursor: pointer;
    -webkit-appearance: button;
    text-transform: none;
    overflow: visible;
    line-height: 1.15;
    margin: 0;
    will-change: transform;
    -moz-osx-font-smoothing: grayscale;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    transition: -webkit-transform .25s ease-out;
    transition: transform .25s ease-out;
    transition: transform .25s ease-out,-webkit-transform .25s ease-out;
  }
  
  &__btn:focus, &__btn:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  
  &__btn-primary {
    background-color: #00449e;
    color: #fff;
  }

  &__disclaimer {
    font-size: 1.35rem;
    color: #807a7a;
    padding: 9px;
  }

  input[type='text'] {
    font-size: 2rem;
    border: 2px solid #333;
    height: 40px;
    margin-right: 1rem;
    position: relative;
    padding-left:7px;
    top: 2px;

    @include small {
        margin-right:0;
        }
    }

    input[type='submit'] {
        font-size: 2rem;
        
        @include small {
            width: 85%;
            margin-top: 1rem;
        }
    }
}
  
  
  
  /**************************\
    Demo Animation Style
  \**************************/
  @keyframes mmfadeIn {
      from { opacity: 0; }
        to { opacity: 1; }
  }
  
  @keyframes mmfadeOut {
      from { opacity: 1; }
        to { opacity: 0; }
  }
  
  @keyframes mmslideIn {
    from { transform: translateY(155%); }
      to { transform: translateY(0); }
  }
  
  @keyframes mmslideOut {
      from { transform: translateY(0); }
      to { transform: translateY(-50%); }
  }
  
  .micromodal-slide {
    display: none;
  }
  
  .micromodal-slide.is-open {
    display: block;
  }
  
  .micromodal-slide[aria-hidden="false"] .modal__overlay {
    animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
  }
  
  .micromodal-slide[aria-hidden="false"] .modal__container {
    animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
  }
  
  .micromodal-slide[aria-hidden="true"] .modal__overlay {
    animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
  }
  
  .micromodal-slide[aria-hidden="true"] .modal__container {
    animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
  }
  
  .micromodal-slide .modal__container,
  .micromodal-slide .modal__overlay {
    will-change: transform;
  }


// Old Modal CSS
.reveal-modal2 {
    position: relative;
    width:100%;
    z-index: 101;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    background:#fff;
    background: linear-gradient(#ddf8c4, #ffffff);
    font-size:1.6rem;
    line-height:2.5rem;
    text-align: left;
    padding: 15px;
    max-width:900px;
    border: 3px solid #000;

    ul {
        margin-left: 3.5rem;
        font-weight: bold;
        font-style: italic;

        @include small {
            margin-left:.5rem;
            margin-right:.5rem;
            list-style: none;
        }

        li {
            line-height:1.6rem;
            margin-bottom:1rem;
        }
    }

    .close {
        display: none;
        font-size: 3.2rem;
        line-height: 0.5;
        position: absolute;
        top: 8px;
        right: 11px;
        color: #000;
        text-shadow: 0 -1px 1px rbga(0, 0, 0, 0.6);
        font-weight: bold;
        cursor: pointer;
    }

    input[type='text'] {
        font-size: 2rem;
        border: 2px solid #333;
        height: 40px;
        margin-right: 1rem;
        position: relative;
        padding-left:7px;
        top: 2px;
        }

    input[type='submit'] {
        font-size: 2rem;
        
        @include small {
            width: 60%;
            margin-top: .5rem;
        }
    }
}

.modal-header {
    font-size: 2.7rem;
    margin-bottom:1rem;
    font-weight:bold;
    color: maroon;
}