$color-primary: #4d967b;
$color-bg: #faf9f4; // page background color
$color-h1: #222;
$color-h2: #333;
$color-h3: #4a616c;

$color-highlight-bg: #ffffde;
$color-highlight: #f8af1e;
$color-danger: #ef290c;
$color-success: #04d454;

$color-link: #034c99;
$color-table-lines: #6f8cab;
