.recommendBox {
    color: #555;
    line-height: 2.5rem;
    padding: 2rem;
    background: #ffffcc;
    border: 3px solid #060;
    border-radius: 10px;

    h2 {
        font-size:2.5rem;
        margin-bottom:2.5rem;
        color: #000;
    }

    a { 
        font-weight:bold;
        color:$color-link;

        &:hover {
            color:darken($color-link, 15%);
        }
    }
}