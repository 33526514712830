.footer {
    margin: 4rem auto 0px;
    padding: 0px;
    font: 1.3rem Arial, Helvetica, sans-serif;
    overflow: hidden;
    z-index: 100;
    position: absolute;
    width: 100%;
    border-top: 2px solid #333;
    background: #242d36;
    color: #425868;

    &__copyright {
        margin-bottom:2rem;
    }

    a {
        color: #a5cfdd;
    }

    &__content {
        display:flex;
        flex-wrap:wrap;
        justify-content: space-between;
    }

    &__container {
        max-width:980px;
        padding: 2rem;
        margin: 0px auto;
    }

    &__title {
        padding-bottom: 5px;
        font-size: 1.6rem;
        font-weight: bold;
        color: #eee;
    }

    &__tile {
        margin-bottom:3rem;
        flex-basis: 25%;

        @include small {
            flex-basis:33%;
        }

        a {
            display:block;
            margin: 1rem 0;
        }
        
        &--4{
            flex-grow:3;
        }
    }
}


