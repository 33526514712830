.table-container {
    overflow: scroll;
    margin-right: -1.5rem;
    margin-left: -1rem;
    padding-right: 1rem;
    margin-bottom:3rem;

    table {  
        width:100%;
        font-family:arial;
    }

    th {
        font-size: 2rem;
        padding: 1rem 1px!important;
        color:#20549e; 
        background: rgb(239, 245, 247);
        border-bottom: 1px solid $color-table-lines!important;
        
        &:first-child{
            padding-left:1rem!important;
        }

        @include small {
            font-size:1.5rem;
        }
    }

    td {
        font-size:1.8rem;
        border-right:1px solid rgba(61, 100, 158, 0.144); 
        padding:.7rem!important;
    
        @include small {
            font-size:1.4rem;
        }
    }
}



table.dataTable.compact thead th, table.dataTable.compact thead td {
    @include small {
        line-height: 1.2;
        padding-top:1rem; padding-bottom:1rem;
    }
    
}




#Finance td {
    height:5rem;
}

#Incentives-home td {
    border-right:1px solid #ccc;
    border-bottom:1px solid #ccc;
}

.dt-center {text-align:center;}

.plusrebate {
    font-size: 1.6rem;
    font-weight:normal;
    color: #079606e0;

    @include small {
        font-size:1rem;
    }
 }


 // News Tables

 .tableizer-table {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.8rem;
    line-height:1.2;
    color: #000;
    border-collapse: collapse;
    width:100%;

    th {
        background: #f5f5f5;
        vertical-align:bottom;
        padding: 1rem!important;
    }

    td {
        padding: 2px 5px 2px;
        border-bottom:1px solid rgba(61, 100, 158, 0.144);
        padding: 7px;
        margin: 0px;

     &:last-child {
            border-right:1px solid $color-table-lines; 
        }

        .trim {
            font-style:italic;
            font-size:1.5rem;
        }
    }

    tr:last-child td{
        border-bottom:1px solid $color-table-lines;
    }

}





 .value{color:#fff!important; font-weight:bold; text-align:center;}
.Excellent{background:#1B9149!important;}
.Good{background:#7FB94A!important;}
.Okay, .OK{background:#FDCB31!important;}
.Poor{background:#EC482E!important;}
.Terrible{background:#BA232B!important;}

.vehicle {
    white-space: nowrap; 
    color:#000;
    @include small {
        white-space:normal;
    }
}
.engine {color:#14ad37; font-style:italic; font-size:.8em;}
.year {color:#9f9f9f; font-style:italic; font-size:.8em;}
.deals {text-align:center; background:#fdfad2; font-weight:bold;
    a {color:#067b15;}
}
.hybrid {font-weight:normal; font-style:italic; color:#555;}
.rebate, .vehicle {font-weight:bold; vertical-align:middle;}
.rebate {
    font-size:2rem!important;
    color:#03458a;

    @include small {
        font-size:1.6rem!important;}
    }

.none {
    text-align:center; 
    color: #1f549e!important; 
    font-size: 2rem; 
    font-weight: bold;
    
    @include small {
        font-size:1.6rem;
    }
}

// font size of at least 16px so safari doesnt zoom
.dataTables_filter {
    float:left!important;
    text-align: left!important;
    background: #eee;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    }
  .dataTables_filter input {
    background:#fff!important;
  }

.dataTables_wrapper .dataTables_filter {
    @include small {
        text-align:center!important;
    }
    input {
        font-size:16px;
    }
}



// PRICES

      
#Prices {
    .rebate {
        color:#000;
        font-size:1.8rem!important;
        @include small {
            font-size:1.5rem!important;
        }
    }
    th {
        color:#20549e; background: rgb(239, 245, 247);
        border: 1px solid rgb(222, 222, 222);
        border-bottom: 3px solid rgb(111, 140, 171)!important;
    }
  
    td {border:1px solid rgba(61, 100, 158, 0.28);}
}
    .seperator { border-right:1px solid #000!important;}
    .seperator-light{ border-right:1px solid #555;}

    .col1, .col1-2 {background:#f3f7f2; font-size:14px;}
    .col2, .col2-2 {background:#ececed; font-size:14px;}
    .col1-2, .col2-2 {font-size:18px;}

    .none {color:#999!important;}

    .year1{ background:#498a48; color:#fff;}
    .year1-bg{ background:#ddeadd; color:#000;}
    .year2{ background:#4f488a; color:#fff;}
    .year2-bg{ background:#dfddea; color:#000;}

    

    
