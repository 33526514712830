.offer {
    margin:3rem -1rem;

    &__header {
        background: $color-primary;
        width:100%;
        display:flex;
        align-items:center;
        padding:1rem;
        border-top-right-radius: .5rem;
        border-top-left-radius: .5rem;
    
        h3 {
            color: #ffffff;
            margin: 0px;
            margin-left:.5rem;
            font: bold 2rem helvetica;
        }
    }

    &__body {
        display:flex;
        width:100%;
        background: lighten($color-primary, 40%);
        background: linear-gradient(lighten($color-primary, 55%), lighten($color-primary, 35%));
        padding:1.5rem 2rem;
        line-height:3rem;
        -moz-border-radius-bottomright: .5rem;
        -moz-border-radius-bottomleft: .5rem;
        border-bottom-right-radius: .5rem;
        border-bottom-left-radius: .5rem;
        color: #000;

        @include small {
            flex-direction:column;
            line-height:2rem;
        }
    }

    &__alert {
        height: 32px;
        float: left;
        padding-right: 5px;
    }
    &__text {
        a { 
            font-weight:bold;
            color:$color-link;

            &:hover {
                color:darken($color-link, 15%);
            }
        }
    }

    &__button {
        padding: 1rem;
        align-self: center;
    }
}