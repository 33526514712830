// Media Queries
// in "em" for accessibility (if people change default font-size, using browser's 16px default)

@mixin small {
    @media (max-width: 34.375em) { @content }; // 550px; 
}

@mixin small-min {
    @media (min-width: 34.375em) { @content }; // 500px min width; 
}

@mixin medium {
    @media (max-width: 43.75em) { @content }; // 700px; 
}

@mixin large {
    @media (max-width: 56.25em) { @content }; // 900px; 
}

@mixin large-min {
    @media (min-width: 56.25em) { @content }; // 900px min width; 
}


@mixin x-large {
    @media (max-width: 85em) { @content }; // 1360px; 
}
