.btn {
	-moz-box-shadow: 3px 4px 0px 0px #1564ad;
	-webkit-box-shadow: 3px 4px 0px 0px #1564ad;
	box-shadow: 3px 4px 0px 0px #1564ad;
	background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #79bbff), color-stop(1, #378de5));
	background:-moz-linear-gradient(top, #79bbff 5%, #378de5 100%);
	background:-webkit-linear-gradient(top, #79bbff 5%, #378de5 100%);
	background:-o-linear-gradient(top, #79bbff 5%, #378de5 100%);
	background:-ms-linear-gradient(top, #79bbff 5%, #378de5 100%);
	background:linear-gradient(to bottom, #79bbff 5%, #378de5 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#79bbff', endColorstr='#378de5',GradientType=0);
	background-color:#79bbff;
	-webkit-border-radius:5px;
	-moz-border-radius:5px;
	border-radius:5px;
	border:1px solid #337bc4;
	display:inline-block;
	cursor:pointer;
	color:#ffffff!important;
	font-size:1.8rem;
	font-weight:bold;
	padding:.75rem 2rem;
	text-decoration:none;
    text-shadow:0px 1px 0px #528ecc;
	white-space:nowrap;
	
	&:hover {
		background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #71afee), color-stop(1, #3486d8));
		background:-moz-linear-gradient(top, #71afee 5%, #3486d8 100%);
		background:-webkit-linear-gradient(top, #71afee 5%, #3486d8 100%);
		background:-o-linear-gradient(top, #71afee 5%, #3486d8 100%);
		background:-ms-linear-gradient(top, #71afee 5%, #3486d8 100%);
		background:linear-gradient(to bottom, #71afee 5%, #3486d8 100%);
		filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#71afee', endColorstr='#3486d8',GradientType=0);
		background-color:#378de5;
	}
	&:active {
		position:relative;
		top:1px;
	}

	&--danger {
		background:linear-gradient(to bottom, #ff7979 5%, #e53737 100%);
		border:1px solid #c43333;
		text-shadow:0px 1px 0px #cc5252;
		box-shadow: 3px 4px 0px 0px #ad1515;

		&:hover {
			background:linear-gradient(to bottom, #ee7171 5%, #d83434 100%);
		}
	}
}


