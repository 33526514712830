.header {
   width: 100%;
   font-size: 1.6rem;
   margin: 0px auto;
   background: #fff;
   background: -moz-linear-gradient(top, #a0d8ef 0%, #d5eaf2 0%, #feffff 39%, #feffff 39%, #feffff 100%);
   background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #a0d8ef),
      color-stop(0%, #d5eaf2),
      color-stop(39%, #feffff),
      color-stop(39%, #feffff),
      color-stop(100%, #feffff)
   );
   background: -webkit-linear-gradient(top, #a0d8ef 0%, #d5eaf2 0%, #feffff 39%, #feffff 39%, #feffff 100%);
   background: -o-linear-gradient(top, #a0d8ef 0%, #d5eaf2 0%, #feffff 39%, #feffff 39%, #feffff 100%);
   background: -ms-linear-gradient(top, #a0d8ef 0%, #d5eaf2 0%, #feffff 39%, #feffff 39%, #feffff 100%);
   background: linear-gradient(to bottom, #a0d8ef 0%, #d5eaf2 0%, #feffff 39%, #feffff 39%, #feffff 100%);

   @include small {
      height: 90px;
   }

   &__container {
      max-width: 980px;
      display: flex;
      flex-wrap: nowrap;
      margin: 0 auto;

      @include small-min {
         min-height: 95px;
      }
   }

   &__logo {
      width: 150px;
      align-self: flex-end;
      min-width: 100px;

      @include small {
         min-width: 75px;
         margin-right: 10px;
      }

      img {
         width: 100%;
         height: auto;
         position: relative;
         top: 6px;
         z-index: 3;

         @include small {
            width: 95%;
            top: 5px;
         }
      }
   }

   &__nav--desktop {
      display: flex;
      padding-top: 1vw;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 1rem;
      margin-right: 1rem;

      @include small {
         display: none;
      }

      &--tagline {
         img {
            width: 70%;
         }
      }

      ul {
         list-style: none;
         display: flex;

         li {
            padding: 7px 10px;
            border: 1px solid #e0dfdf;
            border-bottom: 0px;
            margin-right: 2px;
            -moz-border-radius-topleft: 3px;
            -moz-border-radius-topright: 3px;
            -moz-border-radius-bottomright: 0px;
            -moz-border-radius-bottomleft: 0px;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 0px;
            border-bottom-left-radius: 0px;
            background: #eee;
            background: linear-gradient(180deg, #eee 37%, #ddd 100%);
            transition: all 0.2s ease;

            @include large {
               font-size: 1.8vw;
               padding: 3px 5px;
            }

            &#current_area {
               background: $color-primary;
               border: none;

               a {
                  color: #fff;
               }
            }

            &:hover {
               background: #ddd;
               background: linear-gradient(180deg, #ddd 37%, #ccc 100%);
            }

            a {
               text-decoration: none;
               font-weight: bold;
               color: #333;
               font-family: Helvetica, arial;
               transition: color 0.2s ease;
            }
         }
      }
   }

   &__story {
      display: flex;
      align-items: center;
      width: 265px;
      min-width: 180px;
      margin-left: auto;
      align-self: flex-end;

      include small {
         min-width: 150px;
         margin-top: 10px;
      }

      .storytext {
         min-width: 150px;
         img {
            width: 100%;
            height: auto;
         }
      }

      .storyprofile {
         max-width: 65px;
         min-width: 50px;
         margin-right: 5px;
         img {
            width: 100%;
            height: auto;
         }
      }
   }

   &__bar {
      position: relative;
      width: 100%;
      z-index: 2;
      height: 30px;
      background: $color-primary;

      @include small {
         display: none;
      }
   }

   &__email {
      max-width: 980px;
      padding-top: 0.5rem;
      padding-right: 1rem;
      text-align: right;
      margin: 0 auto;

      &--btn {
         background-color: #59c39c;
         text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.37);
         box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
         font-weight: bold;
         font-family: Helvetica, Arial, sans-serif;
         color: #fff;
         display: inline-block;
         margin-bottom: 0;
         text-align: center;
         vertical-align: middle;
         text-decoration: none;
         -ms-touch-action: manipulation;
         touch-action: manipulation;
         cursor: pointer;
         background-image: none;
         border: 1px solid #3f8269;
         white-space: nowrap;
         padding: 6px 12px;
         font-size: 14px;
         border-radius: 4px;
         -webkit-user-select: none;
         -moz-user-select: none;
         -ms-user-select: none;
         user-select: none;

         @include large {
            font-size: 1.8vw;
         }
      }
   }

   &__nav--mobile {
      width: 100%;
      margin-top: 0.3rem;
      background: #fff;
      display: none;

      @include small {
         display: block;
         position: sticky;
         top: 0px;
         opacity: 0.9;
         z-index: 10;
      }

      ul {
         list-style: none;
         display: flex;

         li {
            padding: 7px 10px;
            flex-grow: 1;
            text-align: center;
            font-family: helvetica, arial;
            font-weight: bold;
            font-size: 3.5vw;
            margin-right: 3px;
            background: #eee;
            background: -moz-linear-gradient(top, #dddddd 0%, #eeeeee 37%);
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #bdd7e0), color-stop(37%, #e0e9f1));
            background: gradient(linear, left top, left bottom, color-stop(0%, #bdd7e0), color-stop(37%, #e0e9f1));

            a {
               color: #000;
               text-decoration: none;
            }

            &#current_area {
               background: $color-primary;
               border-bottom: 3px solid darken($color-primary, 17%);

               a {
                  color: #fff;
               }
            }
            &:last-child {
               margin-right: 0;
            }
         }
      }
   }
}
