.links {
    padding: 20px;
    border-radius: 20px;
    padding-left: 60px;
    line-height:2;
    font-size: 1.6rem;
    margin: auto;
    margin-top: 1rem;
    column-count: 3;
    column-gap: 25px;
    column-rule: solid 1px #acb5ac;
    background: #fffef9;
    box-shadow: 0 0 3px rgba(39,44,49,.02), 0 5px 22px -6px rgba(39, 44, 49, 0.34);
    
        @include small {
            padding: 2rem;
            margin-left:-1rem;
            margin-right:-1rem;
            padding-left:2rem;
            column-count: 2;
            column-gap: 3rem;
        }
    
        ul {
            list-style: none;
        }
        
        a {
            color:#444;
    
            &:hover{
                font-weight:bold;
            }
        }
    
        li:hover {
            background:#f1e9bd;
        }
    
    }