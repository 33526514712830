.g {
    
    &__rectangle {
        width: 336px; 
        height: 280px; 
        float:right;
        margin-left:2rem;
        
        @include large {
        display:none;
        }
    }  

    &__side {
        position:absolute;
        left:-200px;
        width:180px;
        height:900px;

        @include x-large {
            display:none;
        }
    }

    &__left-vertical {
        position:absolute;
        left:-200px;
        top:20px;
        width:180px;

        @include x-large {
            display:none;
        }
    }

    &__right-vertical {
        position:absolute;
        right:-200px;
        top:20px;
        width:180px;

        @include x-large {
            display:none;
        }
    }
}