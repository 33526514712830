html {
   @include small {
      scroll-padding-top: 6rem; // leaves room for sticky header
   }
}

.article {
   max-width: 980px;
   position: relative;
   padding: 4rem;
   margin: 0px auto;
   font-size: 2.2rem;
   color: #444;
   background: #fff;
   -webkit-box-shadow: 1px 0px 10px 0px #faf9f3, 1px 0px 10px 0px #ccc9b4;
   -moz-box-shadow: 1px 0px 10px 0px #faf9f3, 1px 0px 10px 0px #ccc9b4;
   box-shadow: 1px 0px 10px 0px #faf9f3, 1px 0px 10px 0px #ccc9b4;

   @include large {
      font-size: 1.6rem;
   }

   @include small {
      padding: 2rem;
      font-size: 2rem;
      img {
         max-width: 100%;
      }
   }

   &__crumbs {
      margin-bottom: 2rem;

      span {
         margin: 0 2rem;
         @include small {
            margin: 0 1rem;
         }
      }
      a {
         font-weight: normal !important;
         text-decoration: none !important;
      }
   }

   &__text {
      line-height: 1.55;

      b {
         background: #eff2fa;
         color:#000;
      }
   }

   &__date {
      font-style: italic;
      font-size: 1.75rem;
      font-family: arial;
      border-top: 1px solid $color-primary;
      margin-top: 1.5rem;
      margin-bottom: 2rem;
   }

   &__welcome {
      margin: 2rem 0;
      border-top: 1px dotted #000;
      padding-top: 2rem;
   }

   &__about {
      margin: 4rem 0;
      font-size: 1.6rem;
   }

   &__updated {
      font-style: italic;
      font-weight: 200;
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
   }

   &__links {
      ul {
         margin-left: 3rem;
         @include small {
            list-style: none;
            margin-left: 0;
         }

         li {
            line-height: 1.5;

            a {
               font-weight: normal;
            }
         }
      }
   }

   em {
      font-weight: bold;
      font-style: italic;
      color: #444;
   }

   p {
      margin-bottom: 2rem;
      color: #666;
      @include large {
         margin-bottom: 1.5rem;
      }
   }

   a {
      font-weight: bold;
      color: $color-link;

      &:hover {
         color: darken($color-link, 15%);
      }
   }

   hr {
      border: 1px solid #eee;
      margin: 4rem 0;
   }

   ul,
   ol {
      padding-left: 4rem;
   }

   &--disclaimer {
      font-size: 1.4rem;
      font-style: italic;
      padding: 0.5rem 0;
   }

   // images //

   .float-left-image {
      /* Default style for desktop */
      max-width: 30%; /* or any other size */
      height: auto;
      float: left; /* aligns the image to the left */
      margin: 1rem 2rem 2rem 0rem;

      @include small {
         margin: 1rem 1rem 1rem 0rem;
         max-width: 37%;
      }
   }

   .float-right-image {
      /* Default style for desktop */
      max-width: 30%; /* or any other size */
      height: auto;
      float: right; /* aligns the image to the left */
      margin: 1rem 0rem 2rem 2rem;

      @include small {
         margin: 1rem 0rem 1rem 1rem;
         max-width: 37%;
      }
   }

   // special ol / ul styling
   .content-list {
      background: #fbfbfb;
      padding: 3rem;
      padding-left: 4rem;
      font-size: 1.75rem;
      display: inline-block;

      b {
         background: none;
      }

      @include small {
         padding-left: 3rem;
      }
   }

   .content-list.checkmark {
      background: #7ddb9312;
   }

   .content-list.xmark {
      background: #ff7a720f;
   }

   // checkmarks and x-marks
   ul.checkmark,
   ol.checkmark {
      list-style-type: none; /* Remove default bullets */
   }

   ul.checkmark li,
   ol.checkmark li {
      padding-left: 1.5em; /* Make room for the checkmark */
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52'%3E%3Cpolyline fill='none' stroke='%230ec20e' stroke-width='15' stroke-linecap='round' stroke-miterlimit='10' points='2,26 22,46 50,2'/%3E%3C/svg%3E")
         no-repeat;
      background-size: 1em; /* Adjust as needed */
      background-position: left top 0.5rem; /* Position the checkmark */
   }

   ul.xmark,
   ol.xmark {
      list-style-type: none; /* Remove default bullets */
   }

   ul.xmark li,
   ol.xmark li {
      padding-left: 1.5em; /* Make room for the xmark */
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23FF0000' d='M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.203 8.294-8.24 8.24 3.685 3.685 8.237-8.24 8.285 8.203z'/%3E%3C/svg%3E")
         no-repeat;

      background-size: 1em; /* Adjust as needed */
      background-position: left top 0.75rem; /* Position the xmark */
   }

   // blockquote

   blockquote {
      background: #f9f9f9;
      padding: 2rem;
      border-left: 3px solid #999;
      margin-left: 4rem;
   }

   // figure with image

   figure {
      margin: 2rem 0;

      img {
         width: 100%;
         height: auto;
         display: block;
         margin: auto;
      }

      figcaption {
         color: teal;
         font-style: italic;
         text-align: center;
         font-size: 1.6rem;
         font-weight: bold;
      }
   }

   // table of content links within article
   .tocBox {
      display: inline-block;
      background: #fff9f3;
      padding: 2rem;
      font-weight: bold;

      ul {
         @include small {
            padding-left: 1rem;
         }
      }

      &--title {
         font-size: 3rem;
         color: #333;
      }

      a {
         text-decoration: underline;
         text-decoration-color: #dcb58d;
         font-size: 2rem;
         font-weight: normal;
      }

      li {
         padding: 0.5rem 0;
      }
   }

   // special div box

   div.special-box {
      background: #f4fdff;
      padding: 2rem;
      border: 1px dotted #ffc34b;
      border-radius: 5px;
   }

   // table styling

   .content-table {
      border-collapse: collapse;
      margin: 25px 0;
      font-size: 0.9em;
      border-radius: 5px 5px 0 0;
      overflow: scroll;
   }

   .content-table thead tr {
      background-color: #555555;
      color: #ffffff;
      text-align: left;
      font-weight: bold;
   }

   .content-table th,
   .content-table td {
      padding: 1rem;
   }

   .content-table tbody tr {
      border-bottom: 1px solid #dddddd;
   }

   .content-table tbody tr:nth-of-type(even) {
      background-color: #f3f3f3;
   }

   .content-table tbody tr:last-of-type {
      border-bottom: 2px solid #555555;
   }

   .content-table tbody tr.active-row {
      font-weight: bold;
      color: #555555;
   }
}

// INCENTIVES
// incentives logos
.i-image {
   max-width: 100px;
   max-height: 75px;
   padding-top: 1rem;
   float: left;
   margin-right: 2rem;
   margin-bottom: 1rem;
}

.red {
   background: #fee8e8;
   padding: 0 2px;
   font-style: italic;
   font-weight: bold;
}

.green {
   background: #d1f5d8;
   padding: 0 2px;
   font-style: italic;
   font-weight: bold;
}

.chart {
   width: 600px;
   height: auto;
   border: 5px solid #eee;
   display: block;
   margin: auto;

   @include large {
      margin: auto;
   }

   @include medium {
      width: 100%;
      height: auto;
      border: 3px solid #eee;
   }
}

// GUIDE Tab Page
.chapBox {
   padding: 2rem;
   text-align: center;
   width: 100%;
   margin: auto;
   h3 {
      font-size: 25px;
      font-weight: bold;
      color: #000;
      margin-bottom: 1rem;
      border-top: 1px solid #000;
      text-align: center;
      padding-top: 2rem;
   }
}

.chapFloat {
   width: 47%;
   display: inline-block;
   padding: 20px;
   text-align: left;
   vertical-align: top;
   @include small {
      min-width: 320px;
      margin: auto;
      padding: 0px;
   }
}

.bookBox {
   text-align: center;
}
.book {
   text-align: left;
   h2 {
      color: #000;
      font-size: 2.5rem;
      background: #eee;
      padding: 1rem;
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;
   }
   h2 a:hover {
      text-decoration: underline;
   }
   ol,
   ul {
      margin-left: 5rem;
      @include small {
         margin-left: 2.5rem;
      }
   }
   ol li a {
      color: #000;
      font-weight: bold;
      font-size: 2rem;
      padding-top: 7px;
   }
   ul li a {
      font-size: 1.6rem;
      font-weight: normal;
      color: $color-link;
   }
}

// GUIDE ARTICLES

.articleLeft {
   padding-right: 15px;
   width: 199px;
   position: absolute;
   top: 5rem;
   left: -200px;

   @include x-large {
      left: 0;
      padding-right: 2.5rem;
      padding-left: 2.5rem;
   }

   @include large {
      position: relative;
      top: 20;
      left: 0;
      width: 100%;
   }
}

.article-right {
   @include x-large {
      padding-left: 200px;
   }

   @include large {
      padding-left: 0;
   }
}

/* Single Page Guide */
.article-full {
   width: 100%;
   margin-top: -20px;
}

.article-full h1 {
   font-family: 'Dosis', arial;
   font-weight: bold;
   font-size: 55px;
   padding-top: 20px;
   color: #0d2168;
}

.indexLBtitle {
   font: bold 20px arial;
   padding: 3px;
   margin-bottom: 10px;
   padding-left: 0px;
   color: #0b2d1e;
}

.indexLBtitle a {
   color: #000;
}

.indexLBtitle a:hover {
   color: #903;
}

.indexLBlinks {
   padding-left: 15px;
   line-height: 166%;
   margin-top: -8px;
   margin-bottom: 25px;
}

.home-mt {
   margin-top: 1rem !important;
}

.indexLBlinks a {
   color: #2f6192;
   text-decoration: none;
   font-size: 15px;
}

.indexLBlinks a:hover {
   text-decoration: underline;
}

.indexLBlinks ol {
   margin-left: 10px;
   padding-left: 0px;
}

.comments {
   padding: 25px;
   background: #f5faf8;

   -webkit-border-top-left-radius: 10px;
   -moz-border-radius-topleft: 10px;
   border-top-left-radius: 10px;

   -webkit-border-top-right-radius: 10px;
   -moz-border-radius-topright: 10px;
   border-top-right-radius: 10px;

   border: 1px solid #bce8f1;
   border-bottom: 0px;
}

.comments h5 {
   padding: 0px;
   margin: 0px;
   margin-top: -10px;
   font: bold 27px arial;
   color: #063;
}

.related {
   padding: 10px;
   margin-top: 0px;
   background: #f9f9f9;

   -webkit-border-bottom-left-radius: 10px;
   -moz-border-radius-bottomleft: 10px;
   border-bottom-left-radius: 10px;

   -webkit-border-bottom-right-radius: 10px;
   -moz-border-radius-bottomright: 10px;
   border-bottom-right-radius: 10px;

   ul {
      @include small {
         margin-left: 1.5rem !important;
         list-style: square;
      }
   }
}

.related a {
   color: #039;
   font-size: 15px;
}

.relatedTitle {
   color: #000;
   font-size: 24px;
   background: #dff0d8;
   margin: -10px;
   padding: 10px;
   box-shadow: none;
   margin-bottom: 10px;
   border-top: 2px solid #d6e9c6;
   border-bottom: 2px solid #d6e9c6;
}

.subheads {
   overflow: auto;
   color: #000;
   margin-bottom: 0px;
   margin-top: 15px;
   padding: 3px;
   padding-left: 10px;
   font-weight: bold;
   line-height: 100%;
}

.tocjump {
   height: 8rem;
}

.tocLink {
   background: #e8e8e2;
   padding: 7px;
   text-align: center;
   display: block;
   border-radius: 10px;
   font-weight: bold;
   margin-bottom: 15px;
}

.toc {
   @include large {
      width: 100%;
   }
}

.toc-x {
   position: sticky;
   text-align: right;
   top: 11rem;
   display: none;
   flex-direction: row-reverse;

   @include large {
      display: flex !important;
   }

   .circle {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #eee;
      width: 30px;
      height: 30px;
      border-radius: 50%;

      a {
         text-decoration: none;
      }
   }
}

.tocLink,
.toc,
.tocjump {
   display: none !important;
   @include large {
      display: block !important;
   }
}

.clearfix {
   clear: both;
   overflow: auto;
}

// Guide Index Pages

/* Left Float Box */

.indexLB {
   padding-right: 15px;
   width: 199px;
   position: absolute;
   top: 5rem;
   left: -200px;

   @include x-large {
      left: 0;
      padding-right: 2.5rem;
      padding-left: 2.5rem;
   }

   @include large {
      position: relative;
      top: 20;
      left: 0;
      width: 100%;
   }
}

/* Right Float Box */
.indexRB {
   h1 {
      font: bold 4.5rem tahoma, helvetica, arial;
      padding-bottom: 5px;
      padding-top: 15px;
      margin: 0px;
      color: #000;
      text-shadow: 1px 1px 1px #cccccc;
      filter: dropshadow(color=#cccccc, offx=1, offy=1);
   }

   .subtitle {
      margin: 0px;
      font-weight: bold;
      font-style: italic;
      color: #000;
   }

   h2 {
      display: block;
      margin-bottom: 10px;
      font: bold 2.5rem helvetica;
      color: maroon;
      text-shadow: 1px 1px 1px #cccccc;
   }

   h3 {
      display: block;
      margin-bottom: 10px;
      font: bold 3.1rem helvetica;
      color: #900;
      text-shadow: 1px 1px 1px #cccccc;
   }

   h4 {
      display: block;
      color: #000;
      font-size: 150%;
      text-shadow: 1px 1px 1px #cccccc;
   }

   h5 {
      color: #000;
      margin: 0px;
      padding-bottom: 5px;
      font-size: 100%;
      text-shadow: 1px 1px 1px #cccccc;
   }

   hr {
      border: 2px solid #7aa03d;
      margin-top: 0px;
      margin-bottom: 35px;
   }

   .top {
      float: right;
      display: inline;
      background: #7aa03d;
      font: 11px arial;
      color: #fff;
      padding: 4px;
      margin-top: -38px;

      -moz-border-radius-bottomleft: 5px;
      -webkit-border-radius: 0px 0px 0px 5px;
      border-radius: 0px 0px 0px 5px;

      a {
         color: #fff;
      }
   }

   .padding {
      padding-bottom: 45px;
   }

   @include x-large {
      padding-left: 200px;
   }

   @include large {
      padding-left: 0;
   }

   @include small {
      img {
         max-width: 100%;
         max-height: 100%;
      }
   }
}

/* More Articles Box */

.articleBox {
   float: right;
   font-family: arial;
   font-size: 1.5rem;
   background: #fff;
   border: 3px solid #ddd;
   margin-left: 25px;
   margin-bottom: 25px;
   margin-top: 12px;
   margin-right: 15px;
   padding: 10px;

   -moz-border-radius: 8px;
   border-radius: 8px;

   @include medium {
      float: none;
   }

   @include small {
      margin-left: 0;
      margin-right: 0;
      font-size: 2rem;
   }
}

.full-width {
   float: none !important;
   margin: 0;
   margin-bottom: 1rem;
}

.articleBox a {
   color: #039;
   font-weight: bold;
}

.articleBox a:hover {
   text-decoration: underline;
}

.articleBoxTitle {
   font-size: 2rem;
   overflow: auto;
   font-weight: bold;
   background: #d1e0e969;
   color: #0d2283;
   padding: 6px;
   margin: -10px;
   margin-bottom: 10px;
   line-height: 100%;
   -moz-border-radius-topleft: 5px;
   -moz-border-radius-topright: 5px;
   -moz-border-radius-bottomright: 0px;
   -moz-border-radius-bottomleft: 0px;
   -webkit-border-radius: 5px 5px px px;
   border-radius: 5px 5px 0px 0px;
}

.articleBox ol {
   margin-left: 2.5rem;
   padding: 1.5rem;
}

/* Recommended Resources Tip Box */

.tipBox {
   font: normal 12px arial;
   color: #000;
   font-style: italic;
   border: 0px solid #eee;
   padding: 5px;
   margin-bottom: 30px;

   -webkit-border-radius: 8px;
   -moz-border-radius: 8px;
   border-radius: 8px;
   border: 1px solid #fdeaa0;

   -webkit-border-top-left-radius: 0px;
   -moz-border-radius-topleft: 0px;
   border-top-left-radius: 0px;

   -moz-box-shadow: 4px 4px 4px #bbb;
   -webkit-box-shadow: 4px 4px 4px #bbb;
   box-shadow: 4px 4px 4px #bbb;

   background: #fef7c5;
}

.tipBoxpadding {
   padding: 10px;
}

.tipBox a {
   padding: 2px;
   font-size: 17px;
   font-style: normal;
   font-weight: bold;
   color: #039;
}

.tipBox a:hover {
   background: #f2f9ff;
}

.tipBoxLeft {
   float: left;
   width: 45%;
   padding: 10px;
   padding-right: 15px;
   border-right: 3px solid #fdeaa0;
}

.tipBoxRight {
   float: left;
   width: 45%;
   padding: 10px;
   padding-left: 25px;
}

.tipImg {
   margin-left: 0px;
   padding-bottom: 10px;
}

.backto {
   padding: 3px;
   padding-left: 15px;
   font-size: 12px;
   margin-top: 5px;
}

.backto a {
   color: #000;
   font-weight: bold;
}

// Step by step checklist

/*  5 Steps Process */

.stepContainer {
   padding: 6rem;
   padding-top: 3rem;
   @include small {
      padding: 3rem 2rem;
   }
}
.stepBox {
   width: 191px;
   background: #ccc;
   font: bold 24px Arial, Helvetica, sans-serif;
   color: #fff;
   text-align: center;
   padding: 5px;
   border: 1px solid #666;
}

.r {
   background: #fc9;
   border: 2px solid #fff;
   text-align: right;
   font-weight: bold;
   vertical-align: top;
   padding: 5px;
}
.i {
   background: #eee;
   border: 2px solid #fff;
   padding: 5px;
}

.stepsSub {
   font-size: 22px;
   margin: 0px;
   margin-bottom: 20px;
   color: #000;
}

.stepsh2 {
   font-size: 3rem;
   color: #900;
   background: #eee;
   padding: 8px;
   border-top: 1px solid #bce8f1;
   border-bottom: 1px solid #bce8f1;

   -moz-box-shadow: 4px 4px 4px #ccc;
   -webkit-box-shadow: 4px 4px 4px #ccc;
   box-shadow: 0px 4px 3px #ccc;
   @include small {
      font-size: 2rem;
   }
}

.stepsRightBox {
   float: right;
   margin-left: 20px;
   font: 11px arial, helvetica;
   color: #604035;
   padding: 10px;
   background: #eee;
   border: 1px solid #ccc;

   -moz-box-shadow: 4px 4px 4px #ddd;
   -webkit-box-shadow: 4px 4px 4px #ddd;
   box-shadow: 4px 4px 4px #ddd;

   -moz-border-radius: 8px;
   border-radius: 8px;
}

.FBshare {
   padding: 5px;
   background: #000;
   font: 12px Verdana, Geneva, sans-serif;
   color: #fff;
}
.FBshare a {
   color: #ffcc00;
}

#steplinks ul {
   display: flex;
   margin: 0;
   padding: 0px;
   list-style: none;
}

#steplinks li {
   width: 20%;
   margin: 2px;
   margin-top: 0px;
   padding: 10px;
   margin-bottom: -1px;
   line-height: 19px;
   text-align: center;
   color: #000;

   font-size: 2rem;

   -moz-border-radius-bottomleft: 15px;
   -moz-border-radius-bottomright: 15px;

   border-bottom-right-radius: 15px;
   border-bottom-left-radius: 15px;

   background: #d9edf7; /* old browsers */

   background-image: linear-gradient(bottom, rgb(195, 220, 232) 6%, rgb(217, 237, 247) 53%, rgb(230, 243, 250) 78%);
   background-image: -o-linear-gradient(bottom, rgb(195, 220, 232) 6%, rgb(217, 237, 247) 53%, rgb(230, 243, 250) 78%);
   background-image: -moz-linear-gradient(bottom, rgb(195, 220, 232) 6%, rgb(217, 237, 247) 53%, rgb(230, 243, 250) 78%);
   background-image: -webkit-linear-gradient(bottom, rgb(195, 220, 232) 6%, rgb(217, 237, 247) 53%, rgb(230, 243, 250) 78%);
   background-image: -ms-linear-gradient(bottom, rgb(195, 220, 232) 6%, rgb(217, 237, 247) 53%, rgb(230, 243, 250) 78%);

   background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.06, rgb(195, 220, 232)), color-stop(0.53, rgb(217, 237, 247)), color-stop(0.78, rgb(230, 243, 250)));

   @include small {
      font-size: 1.5rem;
   }
}

#steplinks li a {
   font: bold 1.5rem Arial, Helvetica, sans-serif;
}

#steplinks li#current_area {
   background: #dff0d8;
   padding-bottom: 25px;
   line-height: normal;
   font-weight: bold;

   border-top: 0px;

   background-image: linear-gradient(bottom, rgb(200, 217, 195) 6%, rgb(222, 240, 216) 53%, rgb(231, 245, 231) 78%);
   background-image: -o-linear-gradient(bottom, rgb(200, 217, 195) 6%, rgb(222, 240, 216) 53%, rgb(231, 245, 231) 78%);
   background-image: -moz-linear-gradient(bottom, rgb(200, 217, 195) 6%, rgb(222, 240, 216) 53%, rgb(231, 245, 231) 78%);
   background-image: -webkit-linear-gradient(bottom, rgb(200, 217, 195) 6%, rgb(222, 240, 216) 53%, rgb(231, 245, 231) 78%);
   background-image: -ms-linear-gradient(bottom, rgb(200, 217, 195) 6%, rgb(222, 240, 216) 53%, rgb(231, 245, 231) 78%);

   background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.06, rgb(200, 217, 195)), color-stop(0.53, rgb(222, 240, 216)), color-stop(0.78, rgb(231, 245, 231)));
}

#steplinks li#current_area a {
   color: #000;
}

.no-padding {
   padding: 0px !important;
}

/* Homepage CSS */

.homeMessage {
   background: #444;
   text-align: center;
   padding: 1rem;
   a {
      color: #fff;
      text-decoration: none;
      font-size: 1.8rem;
      &:hover {
         color: #eee;
      }
   }
}

.homeBox {
   display: flex;
   align-items: flex-start;
   background: rgb(255, 255, 255); /* Old browsers */
   background: -moz-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 1) 51%, rgba(227, 239, 242, 1) 100%); /* FF3.6+ */
   background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(51%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(227, 239, 242, 1))); /* Chrome,Safari4+ */
   background: -webkit-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 1) 51%, rgba(227, 239, 242, 1) 100%); /* Chrome10+,Safari5.1+ */
   background: -o-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 1) 51%, rgba(227, 239, 242, 1) 100%); /* Opera 12+ */
   background: -ms-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 1) 51%, rgba(227, 239, 242, 1) 100%); /* IE10+ */
   background: radial-gradient(ellipse at center, rgba(255, 255, 255, 1) 51%, rgba(227, 239, 242, 1) 100%); /* W3C */
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e3eff2',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
   padding: 3rem;
   padding-top: 0px;
   padding-bottom: 5rem;
   border-bottom: 5px solid #ccc;

   @include medium {
      flex-direction: column;
   }

   @include small {
      padding: 1rem;
   }

   &__tips {
      align-self: flex-start;
      padding: 2rem;
      margin-left: 15px;
      min-width: 300px;

      li {
         font-size: 1.7rem;

         @include small {
            font-size: 2rem;
         }
      }
      @include small {
         padding: 2rem 1rem;
      }
      h1 {
         font-family: 'Dosis', arial;
         font-weight: bold;
         font-size: 4rem;
         padding-bottom: 0px;
         line-height: 1;
         margin-bottom: 3rem;
         margin-top: 0px;
         color: #000;

         @include small {
            font-size: 3.5rem;
         }
      }
   }

   &__tools {
      border-right: 3px solid #eee;
      padding: 2rem;

      @include medium {
         width: 100%;
         border-right: none;
         border-bottom: 3px solid #eee;
      }

      h1 {
         font-family: 'Dosis', arial;
         font-weight: bold;
         font-size: 4rem;
         padding-bottom: 0px;
         line-height: 1;
         margin-bottom: 3rem;
         margin-top: 0px;
         color: #000;

         @include small {
            font-size: 3.5rem;
         }
      }
   }

   &__flex {
      display: flex;
   }

   &__flex1 {
      max-width: 110px;

      @include small {
         display: none;
      }
   }

   &__flex2 {
      min-width: 200px;
      margin-left: 3rem;

      @include small {
         margin-left: 1.5rem;
      }
   }
}

// News Slider

.newsBox {
   padding: 3rem;
   border-bottom: 1px solid #444;

   h1 {
      font-family: 'Dosis', arial;
      font-weight: bold;
      font-size: 4rem;
      padding-bottom: 0px;
      line-height: 1;
      margin-bottom: 3rem;
      margin-top: 0px;
      color: #000;

      @include small {
         font-size: 3rem;
      }
   }
}
#featured {
   width: 100%;
   display: flex;
}

.flex1 {
   min-width: 350px;
   margin-left: 4rem;
   font-size: 2rem;

   ul li {
      margin: 1.5rem 0;

      @include small {
         margin: 0.75rem 0;
      }
   }

   @include medium {
      min-width: 285px;
   }
   @include small {
      margin-left: 2rem;
   }
}
.flex2 {
   @include small {
      display: none;
   }
}

.chapter {
   font-size: 3rem;
   font-weight: bold;

   @include small {
      background: #eee;
   }
}

.howTitle {
   a {
      margin: 1rem 0;
   }
}

.chapterContainer {
   float: left;
   min-width: 27%;
   margin: 0 2rem;

   @include small {
      float: none;
      margin: 0;
      width: 100%;
   }
}

.tocContainer {
   width: 90%;
   margin-left: 10%;

   @include small {
      width: 100%;
      margin-left: 0;
   }
}

// 5 step checklist

.quicksteps {
   font-size: 16px;
   font-weight: bold;
   color: #000;
   width: 100%;
   padding: 0px;
}
.tasks {
   background: #fcf8e3;
   border: 1px solid #f1de7e;
   padding: 10px;
   width: 750px;
   margin: 10px;
   margin-left: 50px;
   font-size: 15px;
   font-family: Arial, Helvetica, sans-serif;
   font-weight: normal;
   border-radius: 6px;

   @include medium {
      width: unset;
      margin-left: 0;
   }
}

.tasks2 {
   margin-left: 35px;
   width: 700px;
   margin-top: -22px;
   color: #000;

   @include medium {
      width: unset;
   }
}
.quickcheck {
   margin-right: 10px;
}
del {
   text-decoration: line-through;
   color: green;
}

/*	--------------------------------------------------
	Reveal Modals
	-------------------------------------------------- */

.reveal-modal-bg {
   position: fixed;
   height: 100%;
   width: 100%;
   background: #000;
   background: rgba(0, 0, 0, 0.8);
   z-index: 100;
   display: none;
   top: 0;
   left: 0;
}

.reveal-modal {
   visibility: hidden;
   top: 100px;
   left: 50%;
   margin-left: -300px;
   max-width: 520px;
   background: #eee;
   position: absolute;
   z-index: 101;
   padding: 30px 40px 34px;
   -moz-border-radius: 5px;
   -webkit-border-radius: 5px;
   border-radius: 5px;
   -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
   -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
   -box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.reveal-modal.small {
   width: 200px;
   margin-left: -140px;
}
.reveal-modal.medium {
   width: 400px;
   margin-left: -240px;
}
.reveal-modal.large {
   width: 600px;
   margin-left: -340px;
}
.reveal-modal.xlarge {
   width: 800px;
   margin-left: -440px;
}

.reveal-modal .close-reveal-modal {
   font-size: 22px;
   line-height: 0.5;
   position: absolute;
   top: 8px;
   right: 11px;
   color: #000;
   text-shadow: 0 -1px 1px rbga(0, 0, 0, 0.6);
   font-weight: bold;
   cursor: pointer;
}
