.message {
    font-size:1.65rem;
    line-height:2.5rem;
    border-radius:.5rem;
    margin: 2.5rem -1rem;

    a {
        color: $color-link;
        &:hover {
            color: darken($color-link, 15%);
        }
    }
    &--success {
        background: lighten($color-success, 52%);
        color: darken($color-success, 35%);
        padding: 1rem;
        border:1px solid $color-success;
        border-left: .5rem solid $color-success;
    }

    &--highlight {
        background: lighten($color-highlight, 41%);
        color: #000;
        padding: 1rem;
        border-left: .5rem solid $color-highlight;
    }

    &--danger {
        background: lighten($color-danger, 48%);
        color:darken($color-danger, 30%);
        padding: 1rem;
        border-left: .5rem solid $color-danger;
    }

    &--blue {
        background: #f1f6fe;
        color: #01250f;
        padding: 1rem;
        border: none;
        border-left: 0.5rem solid #7ba7f3;
    }

    &--no-margin {
        margin:0;
    }
}