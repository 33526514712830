.h1,
h2,
h3 {
   font-family: Arial, Verdana, sans-serif;
}
.h1 {
   font-size: 5.5rem;
   line-height: 1.2;
   color: $color-h1;
   margin: auto;
   margin-bottom: 4rem;
   text-align: left;

   @include large {
      font-size: 4.5rem;
      margin-bottom: 2rem;
   }

   @include small {
      font-size: 4.5rem;
      margin-bottom: 2rem;
   }

   &--incentives {
      font-size: 5rem;
      text-align: center;

      @include small {
         font-size: 3.3rem;
      }
   }

   &--news {
      margin-bottom: 0px;
   }
}

h2 {
   line-height: 1.2;
   font-size: 4.5rem;
   margin: 1.5rem 0;
   margin-top: 3rem;
   color: $color-h2;

   @include large {
      font-size: 3rem;
   }

   @include small {
      font-size: 3rem;
      margin-bottom: 1rem;
   }
}

h3 {
   font-size: 2.5rem;
   line-height: 1.5;
   color: $color-h3;
   margin: 1.5rem 0;
   margin-top: 3rem;

   .month {
      font-style: italic;
      font-size: 1.75rem;
      font-weight: normal;
      color: #bb9b70;
   }
}

.h3 {
   &--bg {
      margin: 1rem -1rem;
      background: #eee;
      padding: 1rem;
   }
}
